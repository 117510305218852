import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import CaseForm from './CaseForm';
//import CaseFeed from './CaseFeed';
//import ScraperChangeDisplay from './ScraperChangeDisplay';
import DelProductDisplay from './DelProductDisplay';
import Spinner from '../common/Spinner';
import { getProductDetails } from '../../actions/scraperActions';
//import MaterialTable from 'material-table';

import axios from 'axios';

import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-layouts/styles/material.css';
class DeleteProduct extends Component {
  constructor() {
    super();
    this.state = {
      scrapers: [],
      baseurl: ''
    };
  }

  componentDidMount() {
    //console.log('Scrapers.js componentDidMount()');
    this.props.getProductDetails(this.props.location.state);
  }

  delProductData = id => {
    //console.log('id:' + id);    
    axios
      .delete('/api/scraper/delproduct/?id=' + id)
      .then(res => res.data);  
    
    document.getElementById("warningNotifier").style.display = "none";
    document.getElementById("sucessNotifier").style.display = "block";
  }

  render() {
    console.log('EditProduct render');
    //console.log(this.props.scraper);   

    const { scrapers, loading } = this.props.scraper;
    let prodContent;

    if (scrapers === null || loading) {
      prodContent = <Spinner />;
    } else {
      prodContent = <DelProductDisplay scrapers={scrapers} delProductData={this.delProductData} />;           
    }

    return (
      <div className='container'>
        <div className='card bg-info text-white'>
          <div className='card-body'>            
            <div className="text-center"><h2>Delete Product</h2></div>
          </div>
        </div>
        <div className='mb-2'></div>
        {prodContent}        
      </div>
    );
  }
}

DeleteProduct.propTypes = {
  getProductDetails: PropTypes.func.isRequired,
  scraper: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  scraper: state.scraper
});

export default connect(
  mapStateToProps,
  { getProductDetails }
)(DeleteProduct);
