import {
  GET_SCRAPER_CHANGES,
  GET_SCRAPER_HOME,
  //GET_SCRAPER_CHANGES_DETAIL,
  GET_PRODUCT_DETAILS,  
  GET_PRODUCT_CHANGES,
  SCRAPER_LOADING
} from '../actions/types';

const initialState = {
  scrapers: [],
  scraper: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SCRAPER_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_SCRAPER_CHANGES:
      return {
        ...state,
        scrapers: action.payload,
        loading: false
      };
    case GET_SCRAPER_HOME:
      return {
        ...state,
        scrapers: action.payload,
        loading: false
      };
    case GET_PRODUCT_DETAILS:
      return {
        ...state,
        scrapers: action.payload,
        loading: false
      };  
    case GET_PRODUCT_CHANGES:
      return {
        ...state,
        scrapers: action.payload,
        loading: false
      };            
    //case GET_SCRAPER_CHANGES_DETAIL:
    //  return {
    //    ...state,
    //    change: action.payload,
    //    loading: false
    //  };
    default:
      return state;
  }
}
