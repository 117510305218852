import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import CaseForm from './CaseForm';
//import CaseFeed from './CaseFeed';
//import ScraperChangeDisplay from './ScraperChangeDisplay';
import ScraperTableDisplay from './ScraperTableDisplay';
import Spinner from '../common/Spinner';
import { getScraperChanges } from '../../actions/scraperActions';
//import MaterialTable from 'material-table';

import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-layouts/styles/material.css';
class Scrapers extends Component {
  constructor() {
    super();
    this.state = {
      scrapers: [],
      baseurl: ''
    };
  }

  componentDidMount() {
    //console.log('Scrapers.js componentDidMount()');
    this.props.getScraperChanges(this.props.location.state);
  }

  render() {
    //console.log('Scrapers render');
    //console.log(this.props.scraper);
    const { scrapers, loading } = this.props.scraper;
    let scraperContent;
    let baseurl;
    if (scrapers === null || loading) {
      scraperContent = <Spinner />;
    } else {
      scraperContent = <ScraperTableDisplay scrapers={scrapers} baseurl={this.props.location.state.baseurl}/>;
      try {
        baseurl = this.props.location.state.baseurl;
      } catch (e) {
        baseurl = 'All Urls';
      }
    }

    return (
      <div className='container'>
        <div className='card bg-info text-white'>
          <div className='card-body'>
            <span className="float-left"><Link className="btn btn-secondary" to="/scraperhome" role="button">Back</Link></span>
            <span className="float-right">Scraper Changes: <b>{baseurl}</b></span>
          </div>
        </div>
        <div className='mb-2'></div>
        <div className="table-responsive-md">
        <table className='table table-bordered'>
          <thead className='thead-light'>
            <tr>
            <th>Url Title</th>
            <th className="text-center">Change Count</th>
            <th className="text-center">Last Change Date</th>
            <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>{scraperContent}</tbody>
        </table>
        </div>
      </div>
    );
  }
}

Scrapers.propTypes = {
  getScraperChanges: PropTypes.func.isRequired,
  scraper: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  scraper: state.scraper
});

export default connect(
  mapStateToProps,
  { getScraperChanges }
)(Scrapers);
