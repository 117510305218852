import React, { Component } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class ScraperTableDisplay extends Component {
  render() {
    const { scrapers } = this.props;
    //console.log('Inside ScraperTableDisplay');
    //console.log(this.props);
    //console.log('Scrapers 2nd recordset');
    //console.log(scrapers[1]);
    //var scraperdetail = scrapers[1];
    //console.log(scraperdetail);
    //console.log(this.props.data);
    return scrapers.map(scraper => {
      return (
        <tr key={scraper.content_id}>
          <td>
            <a href={scraper.url} target="_blank" rel="noopener noreferrer">
              {scraper.title}
            </a>
          </td>
          <td className="text-center">
          {scraper.change_count !== 0 ? (
            <Link to={{pathname:'/viewchanges', state:{ id: scraper.content_id, baseurl: this.props.baseurl }}}>
              <h6><span className="badge badge-primary">{scraper.change_count}</span></h6>
            </Link>
          ) : (
            <h6><span className="badge badge-secondary">{scraper.change_count}</span></h6>  
          )}                               
          </td>
          <td className="text-center">
            <Moment format="MM/DD/YYYY">{scraper.latest_change_date}</Moment>
          </td>
          <td className="text-center">
            <div className="d-icons">
              <Link to={{pathname:'/productdetails', state:{ id: scraper.content_id }}} role="button" className="btn btn-primary btn-sm"> View </Link>{' '}
              <Link to={{pathname:'/editproduct', state:{ id: scraper.content_id }}} role="button" className="btn btn-warning btn-sm"> Edit </Link>{' '} 
              <Link to={{pathname:'/delproduct', state: { id: scraper.content_id }}} role="button" className="btn btn-danger btn-sm"> Delete</Link>
            </div>
            <div className="m-icons">
              <Link to={{pathname:'/productdetails', state:{ id: scraper.content_id }}} className="text-primary"><i class="fas fa-info-circle"></i> </Link> 
              <Link to={{pathname:'/editproduct', state:{ id: scraper.content_id }}} className="text-warning"><i class="fas fa-edit"></i> </Link>
              <Link to={{pathname:'/delproduct', state: { id: scraper.content_id }}} className="text-danger"><i class="fas fa-trash"></i></Link>
            </div>  
          </td>
        </tr>
      );
    });
  }
}

ScraperTableDisplay.propTypes = {
  scrapers: PropTypes.array.isRequired
};

export default ScraperTableDisplay;
