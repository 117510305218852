import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ViewChangesDisplay from './ViewChangesDisplay';
import Spinner from '../common/Spinner';
import { getProductChanges } from '../../actions/scraperActions';

import axios from 'axios';

import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-layouts/styles/material.css';
class ViewChanges extends Component {
  constructor() {
    super();
    this.state = {
      scrapers: [],
      baseurl: '',
      id: ''
    };
  }

  submitAcceptChanges = e => {
    //alert('Updating Database..');
    //return false;
    e.preventDefault();
    var id = this.props.location.state.id;       
    //alert(id);    
    axios
      .put('/api/scraper/acceptchanges/?id=' + id)
      .then(res => res.data);      

    document.getElementById("warningNotifier").style.display = "none";
    document.getElementById("contentListDisplay").style.display = "none";
    document.getElementById("sucessNotifier").style.display = "block";
  }; 

  confirmAcceptChanges = e => {
    document.getElementById("contentListDisplay").style.display = "none";
    document.getElementById("warningNotifier").style.display = "block";
    
  }

  cancelAcceptChanges = e => {
    document.getElementById("warningNotifier").style.display = "none";
    document.getElementById("sucessNotifier").style.display = "none";
    document.getElementById("contentListDisplay").style.display = "block";
  }

  submitAcceptItems = (id) => {
    axios
      .put('/api/scraper/acceptchangesitem/?id=' + id)
      .then(res => res.data);  

    document.getElementById("btn-accept-item-"+id).style.display = "none";
    document.getElementById("btn-accepted-item-"+id).style.display = "inline";
    document.getElementById("btn-accept-undo-"+id).style.display = "inline";

    document.getElementById("card-header-"+id).className = "card-header small bg-light text-muted";
    document.getElementById("card-body-"+id).className = "card-body small bg-light text-muted";
  }

  undoAcceptItems = (id) => {
    axios
      .put('/api/scraper/undoacceptchangesitem/?id=' + id)
      .then(res => res.data); 

    document.getElementById("btn-accept-item-"+id).style.display = "block";
    document.getElementById("btn-accepted-item-"+id).style.display = "none";
    document.getElementById("btn-accept-undo-"+id).style.display = "none";

    document.getElementById("card-header-"+id).className = "card-header small";
    document.getElementById("card-body-"+id).className = "card-body small";
  }

  componentDidMount() {
    //console.log('Scrapers.js componentDidMount()');
    this.props.getProductChanges(this.props.location.state);
  }

  render() {   
    const { scrapers, loading } = this.props.scraper;
    let dataContent;
    let baseurl;
    let backBtn;
    let acceptBtn;
    //console.log('View changes render');
    //console.log(scrapers);

    if (scrapers === null || loading) {
      dataContent = <Spinner />;
      backBtn = '';
      acceptBtn = ''
    } else {      
      dataContent = <ViewChangesDisplay scrapers={scrapers}  submitAcceptItems={this.submitAcceptItems} undoAcceptItems={this.undoAcceptItems}/>;      
       try {
        baseurl = this.props.location.state.baseurl;
      } catch (e) {
        baseurl = 'All Urls';
      } 
      acceptBtn = <button type="button" className="btn btn-primary" onClick={this.confirmAcceptChanges}>Accept All Changes</button> ;
      backBtn = <Link className="btn btn-secondary" to={{pathname: '/scraper',state: { baseurl: baseurl }}} role="button">Cancel</Link>;
    }

    return (
      <div className='container'>
        <div className='card bg-info text-white'>
          <div className='card-body text-center'>
            <h2>Preview Changes</h2>
          </div>
        </div>
        <div className='mb-2'></div>
        <div id='contentListDisplay'>
        {dataContent}
        <div className="text-center mt-3">{acceptBtn} {backBtn}</div>   
        </div>
        <div id="warningNotifier" class="alert alert-warning" style={{display:"none"}}>
          <div className="text-center p-3">
            <h3>Are you sure you want to accept all changes on this product?</h3>            
            <button type="button" className="btn btn-secondary" onClick={this.cancelAcceptChanges}>Cancel</button>{' '} 
            <button type="button" className="btn btn-primary" onClick={this.submitAcceptChanges}>Yes</button>            
          </div>
        </div>
        <div id="sucessNotifier" class="alert alert-success" style={{display:"none"}}>
          <div className="text-center p-3">
            <h3>Product changes updated successfully!</h3>        
            <Link className="btn btn-secondary" to={{pathname: '/scraper',state: { baseurl: baseurl }}} role="button">Done</Link>         
          </div>
        </div>   
      </div>
    );
  }
}

ViewChanges.propTypes = {
  getProductChanges: PropTypes.func.isRequired,
  scraper: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  scraper: state.scraper
});

export default connect(
  mapStateToProps,
  { getProductChanges }
)(ViewChanges);
