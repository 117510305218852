import React, { Component } from 'react';
//import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class ProductDetailsDisplay extends Component {
  render(){
    const { scrapers } = this.props;
    const productdata = scrapers[0];
    //console.log('Inside ScraperTableDisplay');
    //console.log(scrapers);
    //console.log(productdata.title);

    return(
      <table key={productdata.content_id} className='table table-bordered'>
        <tr>
          <td width="20%"><b>Title:</b></td>
          <td>{productdata.title}</td>        
        </tr>
        <tr>
          <td><b>URL:</b></td>
          <td><a href={productdata.url} target="_blank" rel="noopener noreferrer">{productdata.url}</a></td>        
        </tr>
        <tr>
          <td><b>Price:</b></td>
          <td>{productdata.price}</td>        
        </tr>
        <tr>
          <td><b>Description:</b></td>
          <td>{productdata.product_description}</td>        
        </tr> 
        <tr>
          <td className="text-center" colSpan="2">
          <Link className="btn btn-secondary" to={{pathname: '/scraper',state: { baseurl: productdata.base_url }}} role="button">Back</Link> <Link className="btn btn-warning" to={{pathname: '/editproduct',state: { id: productdata.content_id }}} role="button">Edit</Link>
          </td>
        </tr>  
        </table>
    )
  }
}

ProductDetailsDisplay.propTypes = {
  scrapers: PropTypes.array.isRequired
};

export default ProductDetailsDisplay;
