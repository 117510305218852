import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class ScraperTableHome extends Component {
  setRowDataID = (param,id) => {
    return param+"-"+id;
  }

  setRowDataStyle = (num) => {    
    if(num!==0){      
      return "site-row-active";      
    }else{      
      return "site-row-not-active";      
    }          
  }

  

  render() {
    const { scrapers } = this.props;
    //console.log('Inside ScraperTableHome');
    //console.log(scrapers);
    //console.log('Scrapers 2nd recordset');
    //console.log(scrapers[1]);
    //var scraperdetail = scrapers[1];
    //console.log(scraperdetail);
    //console.log(this.props.data);
    return scrapers.map(scraper => {
      return (
        <Fragment key={scraper.id}>          
            <tr id={this.setRowDataID('tr-site-row',scraper.id)} className={this.setRowDataStyle(scraper.active)}>
              <td className="text-center">
              {scraper.active !== 0 ? (                                
                <span>
                  <input id={this.setRowDataID('input-active',scraper.id)} type="checkbox" value="1" checked="checked" onClick={this.props.setSiteDeactive.bind(this, scraper.id)}></input>
                  <input id={this.setRowDataID('input-not-active',scraper.id)} type="checkbox" value="0" onClick={this.props.setSiteActive.bind(this, scraper.id)} style={{display:"none"}}></input>
                </span>

              ) : (  
                <span>
                  <input id={this.setRowDataID('input-active',scraper.id)} type="checkbox" value="1" onClick={this.props.setSiteDeactive.bind(this, scraper.id)} style={{display:"none"}}></input>
                  <input id={this.setRowDataID('input-not-active',scraper.id)} type="checkbox" value="0" onClick={this.props.setSiteActive.bind(this, scraper.id)}></input>
                </span>
              )}                
              </td>
              <td>
                <a
                  href={scraper.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {scraper.website}
                </a>
              </td>              
              <td className="text-center">
                <h5><span className="badge badge-secondary">{scraper.change_count}</span></h5>                 
              </td>
              <td className="text-center">
                <Link
                  to={{
                    pathname: '/scraper',
                    state: { baseurl: scraper.website }
                  }} role="button" className="btn btn-primary"
                >
                  View
                </Link>
              </td>
              <td className="text-center">                  
                <Link
                  to={{
                    pathname: '/scraper',
                    state: { baseurl: scraper.website }
                  }} role="button" className="btn btn-primary"
                >
                  View
                </Link>    
              </td>
            </tr>          
        </Fragment>
      );
    });
  }
}

ScraperTableHome.propTypes = {
  scrapers: PropTypes.array.isRequired
};

export default ScraperTableHome;
