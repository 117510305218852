import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import CaseForm from './CaseForm';
//import CaseFeed from './CaseFeed';
//import ScraperChangeDisplay from './ScraperChangeDisplay';
import ScraperTableHome from './ScraperTableHome';

import Spinner from '../common/Spinner';
import { getScraperHome } from '../../actions/scraperActions';
//import MaterialTable from 'material-table';

import axios from 'axios';

import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-layouts/styles/material.css';
class ScraperHome extends Component {
  constructor() {
    super();
    this.state = {
      scrapers: []
    };
  }

  componentDidMount() {
    //console.log('Scrapers.js componentDidMount()');
    this.props.getScraperHome();
  }

  setSiteActive  = id => {
    axios
      .put('/api/scraper/setsiteactive/?id=' + id)
      .then(res => res.data); 

    document.getElementById("tr-site-row-"+id).className = "site-row-active";    
    document.getElementById("input-not-active-"+id).checked = false;
    document.getElementById("input-not-active-"+id).style.display = "none";

    document.getElementById("input-active-"+id).checked = true;
    document.getElementById("input-active-"+id).style.display = "inline";           
  }

  setSiteDeactive = id => {
    axios
      .put('/api/scraper/setsitedeactive/?id=' + id)
      .then(res => res.data); 

    document.getElementById("tr-site-row-"+id).className = "site-row-not-active";    
    document.getElementById("input-not-active-"+id).checked = false;
    document.getElementById("input-not-active-"+id).style.display = "inline";

    document.getElementById("input-active-"+id).checked = false;
    document.getElementById("input-active-"+id).style.display = "none";
  }

  render() {
    const { scrapers, loading } = this.props.scraper;
    let scraperContent;
    //console.log('Scrapers.js Inside Render');
    //console.log(this.props.scraper);
    if (scrapers === null || loading) {
      scraperContent = <Spinner />;
    } else {
      scraperContent = <ScraperTableHome scrapers={scrapers} setSiteActive={this.setSiteActive} setSiteDeactive={this.setSiteDeactive}/>;
      //console.log(scraperContent);
    }

    return (
      <div className='container'>        
        <div className='card bg-info text-white'>
          <div className='card-body'><h3>Scraper URLs</h3></div>
        </div>
        <div className='mb-2'></div>
        <div className="table-responsive-md"> 
        <table className='table table-bordered'>
          <thead className='thead-light'>
            <tr>
              <th className="text-center">Active</th>
              <th className="text-center">Sites (external links)</th>              
              <th className="text-center">Number Changes</th>
              <th className="text-center">Changes</th>
              <th className="text-center">Logs</th>
            </tr>
          </thead>
          <tbody>
          {scraperContent}
          </tbody>
        </table>
        </div>
      </div>
    );
  }
}

ScraperHome.propTypes = {
  getScraperHome: PropTypes.func.isRequired,
  scraper: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  scraper: state.scraper
});

export default connect(
  mapStateToProps,
  { getScraperHome }
)(ScraperHome);
