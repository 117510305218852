import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import CaseForm from './CaseForm';
//import CaseFeed from './CaseFeed';
//import ScraperChangeDisplay from './ScraperChangeDisplay';
import ProductDetailsDisplay from './ProductDetailsDisplay';
import Spinner from '../common/Spinner';
import { getProductDetails } from '../../actions/scraperActions';
//import MaterialTable from 'material-table';

import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-layouts/styles/material.css';
class ProductDetails extends Component {
  constructor() {
    super();
    this.state = {
      scrapers: [],
      baseurl: ''
    };
  }

  componentDidMount() {
    //console.log('Scrapers.js componentDidMount()');
    this.props.getProductDetails(this.props.location.state);
  }

  render() {
    //console.log('ProductDetails render');
    //console.log(this.props.scraper);   
    const { scrapers, loading } = this.props.scraper;
    let prodContent;
    //let baseurl;
    //const scrapdata = this.props.scraper.scrapers[0];
    
    if (scrapers === null || loading) {
      prodContent = <Spinner />;
    } else {
      prodContent = <ProductDetailsDisplay scrapers={scrapers} />;
     
      /* try {
        baseurl = this.props.scraper.scrapers[0].base_url;
      } catch (e) {
        baseurl = 'All Urls';
      } */
    }

    return (
      <div className='container'>
        <div className='card bg-info text-white'>
          <div className='card-body'>            
            <div className="text-center"><h2>View Product Details</h2></div>
          </div>
        </div>
        <div className='mb-2'></div>
        {prodContent}        
      </div>
    );
  }
}

ProductDetails.propTypes = {
  getProductDetails: PropTypes.func.isRequired,
  scraper: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  scraper: state.scraper
});

export default connect(
  mapStateToProps,
  { getProductDetails }
)(ProductDetails);
