import React, { Component } from 'react';
import Login from './components/auth/Login';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions';
import { clearCurrentProfile } from './actions/profileActions';

import { Provider } from 'react-redux';
import store from './store';
import PrivateRoute from './components/common/PrivateRoute';

import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Landing from './components/layout/Landing';
import Register from './components/auth/Register';

//import scrapers from './components/scrapers/scrapers';
import Profiles from './components/profiles/Profiles';
import NotFound from './components/not-found/NotFound';
//import ScraperChanges from './components/scraper/ScraperChanges';
import Scrapers from './components/scraper/Scrapers';
import ScraperHome from './components/scraper/ScraperHome';
import ProductDetails from './components/scraper/ProductDetails';
import EditProduct from './components/scraper/EditProduct';
import DeleteProduct from './components/scraper/DeleteProduct';
import ViewChanges from './components/scraper/ViewChanges';

import './App.css';
//Check for token
if (localStorage.jwtToken) {
  //Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  //Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  //Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    // TODO Clear current profile
    store.dispatch(clearCurrentProfile());
    //Redirect to login
    window.location.href = '/login';
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Navbar />
            <Route exact path="/" component={Landing} />
            <div className="container">
              <Route exact path="/register" component={Register} />
              <Route exact path="/login" component={Login} />
              <Switch>
                <PrivateRoute exact path="/profiles" component={Profiles} />
              </Switch>
              <Switch>
                <PrivateRoute exact path="/scraper" component={Scrapers} />
              </Switch>
              <Switch>
                <PrivateRoute
                  exact
                  path="/productdetails"
                  component={ProductDetails}
                />
              </Switch>
              <Switch>
                <PrivateRoute
                  exact
                  path="/editproduct"
                  component={EditProduct}
                />
              </Switch>
              <Switch>
                <PrivateRoute
                  exact
                  path="/delproduct"
                  component={DeleteProduct}
                />
              </Switch>
              <Switch>
                <PrivateRoute
                  exact
                  path="/viewchanges"
                  component={ViewChanges}
                />
              </Switch>
              <Switch>
                <PrivateRoute
                  exact
                  path="/scraperhome"
                  component={ScraperHome}
                />
              </Switch>

              <Route exact path="/not-found" component={NotFound} />
            </div>
            <Footer />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
