export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_SCRAPER_CHANGES = 'GET_SCRAPER_CHANGES';
export const GET_SCRAPER_CHANGES_DETAIL = 'GET_SCRAPER_CHANGES_DETAIL';
export const SCRAPER_LOADING = 'SCRAPER_LOADING';
export const GET_SCRAPER_HOME = 'GET_SCRAPER_HOME';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';
export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS';
export const GET_PRODUCT_CHANGES = 'GET_PRODUCT_CHANGES';