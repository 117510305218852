import React, { Component } from 'react';
//import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
//import { editProductDetails } from '../../actions/scraperActions';

class DelProductDisplay extends Component {      

  onSubmit = e => {
    //alert('Updating Database..');
    //return false;
    e.preventDefault();
    var id = this.props.scrapers[0].content_id;        
    this.props.delProductData(id);     
  }; 

  render() {
    console.log(this.props);
    //this.setState({ title: 'John' });

    
    var baseurl = this.props.scrapers[0].base_url;
    var title = this.props.scrapers[0].title;
  
    return (    
      <div>
      <div id="warningNotifier" class="alert alert-warning">
        <div className="text-center p-3">
          <h3>Are you sure you want to delete this product?</h3>
          <h4 className="m-3"><b>{title}</b></h4>
          <Link className="btn btn-secondary" to={{pathname: '/scraper',state: { baseurl: baseurl }}} role="button">Cancel</Link>{" "} 
          <input type="button" value="Yes" onClick={this.onSubmit} className="btn btn-primary" />
        </div>
      </div>
      <div id="sucessNotifier" class="alert alert-success" style={{display:"none"}}>
        <div className="text-center p-3">
          <h3>Product deleted successfully!</h3>        
          <Link className="btn btn-secondary" to={{pathname: '/scraper',state: { baseurl: baseurl }}} role="button">Done</Link>         
        </div>
      </div>  
      </div>
    );
   
  }
}

DelProductDisplay.propTypes = {
  scrapers: PropTypes.array.isRequired
};

export default DelProductDisplay;
