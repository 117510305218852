import axios from 'axios';

import {
  GET_SCRAPER_CHANGES,
  GET_ERRORS,
  //GET_SCRAPER_CHANGES_DETAIL,
  SCRAPER_LOADING,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_CHANGES,  
  GET_SCRAPER_HOME
} from './types';

//Get Scraper URL lists
export const getScraperHome = () => dispatch => {
  //console.log('Inside getScraperHome');
  dispatch(setScraperLoading());
  axios
    .get('/api/scraper')
    .then(res => res.data)
    .then(data =>
      dispatch({
        type: GET_SCRAPER_HOME,
        payload: data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//Get Scraper Changes
export const getScraperChanges = baseurlparam => dispatch => {
  //console.log('Inside getScraperChanges');
  dispatch(setScraperLoading());
  //console.log(baseurlparam.baseurl);
  axios
    .get('/api/scraper/summary/?baseurl=' + baseurlparam.baseurl)
    .then(res => res.data)
    .then(data =>
      dispatch({
        type: GET_SCRAPER_CHANGES,
        payload: data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//Get Product Details
export const getProductDetails = idparam => dispatch => {
  //console.log('Inside getProductDetails');
  dispatch(setScraperLoading());
  //console.log(baseurlparam.baseurl);
  axios
    .get('/api/scraper/proddetails/?id=' + idparam.id)
    .then(res => res.data)
    .then(data =>
      dispatch({
        type: GET_PRODUCT_DETAILS,
        payload: data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//Get Product Details
export const getProductChanges = idparam => dispatch => {  
  dispatch(setScraperLoading());
  //console.log('getProductChanges');
  //console.log(idparam);
  axios
    .get('/api/scraper/prodchanges/?id=' + idparam.id + '&baseurl=' + idparam.baseurl)
    .then(res => res.data)
    .then(data =>
      dispatch({
        type: GET_PRODUCT_CHANGES,
        payload: data
      }) 
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//Set loading state
export const setScraperLoading = () => {
  return {
    type: SCRAPER_LOADING
  };
};
