import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import CaseForm from './CaseForm';
//import CaseFeed from './CaseFeed';
//import ScraperChangeDisplay from './ScraperChangeDisplay';
import EditProductDisplay from './EditProductDisplay';
import Spinner from '../common/Spinner';
import { getProductDetails } from '../../actions/scraperActions';

//import MaterialTable from 'material-table';

import axios from 'axios';

import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-layouts/styles/material.css';
class EditProduct extends Component {
  constructor() {
    super();
    this.state = {
      scrapers: [],
      baseurl: ''
    };
  }  

  componentDidMount() {
    //console.log('Scrapers.js componentDidMount()');
    this.props.getProductDetails(this.props.location.state);
  }

  updateProduct = (id,title,price,description) => {
    //console.log('id:' + id);
    //console.log('title:' + title);
    //console.log('price:' + price);
    //console.log('description:' + description);
    //alert('product updated successfully.');

    axios
      .put('/api/scraper/editprod/?id=' + id, {
        title: title,
        price: price,
        description: description
      })
      .then(res => res.data);      

    document.getElementById("sucessNotifier").style.display = "block";
  }
 

  render() {
    //console.log('EditProduct render');
    //console.log(this.props.scraper);   

    const { scrapers, loading } = this.props.scraper;
    let prodContent;

    if (scrapers === null || loading) {
      prodContent = <Spinner />;
    } else {
      prodContent = <EditProductDisplay scrapers={scrapers} updateProduct={this.updateProduct} />;           
    }

    return (
      <div className='container'>
        <div className='card bg-info text-white'>
          <div className='card-body'>            
            <div className="text-center"><h2>Edit Product Details</h2></div>
          </div>
        </div>
        <div className='mb-2'></div>
        {prodContent}        
      </div>
    );
  }  

}

EditProduct.propTypes = {
  getProductDetails: PropTypes.func.isRequired,
  scraper: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  scraper: state.scraper
});

export default connect(
  mapStateToProps,
  { getProductDetails }
)(EditProduct);
