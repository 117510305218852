import React, { Component } from 'react';
import Moment from 'react-moment';
//import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class ViewChangesDisplay extends Component {
  getAcceptedStyle = num => {
    if (num !== 0) {
      return 'card-body small bg-light text-muted';
    } else {
      return 'card-body small';
    }
  };

  setAcceptDataID = (param, id) => {
    return param + '-' + id;
  };

  render() {
    const { scrapers } = this.props;
    //console.log('ViewChangesDisplay render');

    return scrapers.map(scraper => {
      return (
        <div>
          <div key={scraper.change_id} className="card">
            {scraper.accept !== 0 ? (
              <div className="card-header small bg-light">
                <div className="scrape-date float-left text-muted">
                  Scrape Date:{' '}
                  <i>
                    <Moment format="MM/DD/YYYY hh:mm A">
                      {scraper.scrape_date}
                    </Moment>
                  </i>
                </div>
                <div className="scrape-accept-btn float-right">
                  <button
                    type="button"
                    className="btn btn-default btn-sm small text-muted cursor-default"
                  >
                    Accepted
                  </button>
                </div>
              </div>
            ) : (
              <div
                id={this.setAcceptDataID('card-header', scraper.change_id)}
                className="card-header small"
              >
                <div className="scrape-date float-left">
                  Scrape Date:{' '}
                  <i>
                    <Moment format="MM/DD/YYYY hh:mm A">
                      {scraper.scrape_date}
                    </Moment>
                  </i>
                </div>
                <div className="scrape-accept-btn float-right">
                  <button
                    id={this.setAcceptDataID(
                      'btn-accepted-item',
                      scraper.change_id
                    )}
                    type="button"
                    className="btn btn-default btn-sm small text-muted cursor-default"
                    style={{ display: 'none' }}
                  >
                    Accepted
                  </button>{' '}
                  <button
                    id={this.setAcceptDataID(
                      'btn-accept-undo',
                      scraper.change_id
                    )}
                    type="button"
                    className="btn btn-info btn-sm small"
                    onClick={this.props.undoAcceptItems.bind(
                      this,
                      scraper.change_id
                    )}
                    style={{ display: 'none' }}
                  >
                    Undo
                  </button>
                  <button
                    id={this.setAcceptDataID(
                      'btn-accept-item',
                      scraper.change_id
                    )}
                    type="button"
                    className="btn btn-primary btn-sm small"
                    onClick={this.props.submitAcceptItems.bind(
                      this,
                      scraper.change_id
                    )}
                  >
                    Accept Changes
                  </button>
                </div>
              </div>
            )}

            <div
              id={this.setAcceptDataID('card-body', scraper.change_id)}
              className={this.getAcceptedStyle(scraper.accept)}
            >
              <div>
                <b>Title:</b> {scraper.title}
              </div>
              <div>
                <b>URL:</b>{' '}
                <a href={scraper.url} target="_blank" rel="noopener noreferrer">
                  {scraper.url}
                </a>
              </div>
              <div>
                <b>Price:</b> {scraper.price}
              </div>
              <div>
                <b>Description:</b> {scraper.product_description}
              </div>
              <div>
                <b>Change:</b> {scraper.scrape_change}
              </div>
            </div>
            {/* <div className="card-footer">Scrape Change: {scraper.scrape_change}</div>  */}
          </div>
          <div className="m-4"></div>
        </div>
      );
    });
  }
}

ViewChangesDisplay.propTypes = {
  scrapers: PropTypes.array.isRequired
};

export default ViewChangesDisplay;
