import React, { Component } from 'react';
//import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
//import { editProductDetails } from '../../actions/scraperActions';

class EditProductDisplay extends Component {    
  state = {    
    title: this.props.scrapers[0].title,
    price: this.props.scrapers[0].price,
    description: this.props.scrapers[0].product_description
  };  

  onSubmit = e => {
    //alert('Updating Database..');
    //return false;
    e.preventDefault();
    var id = this.props.scrapers[0].content_id;    
    var title = this.state.title;
    var price = this.state.price;
    var description =  this.state.description;
    this.props.updateProduct(id,title,price,description);    
    //this.setState({ title: "" });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    //console.log(this.props);
    //this.setState({ title: 'John' });

    //const { scrapers } = this.props;
    //console.log('Inside ScraperTableDisplay');
    //console.log(scrapers);
    //console.log('Scrapers 2nd recordset');
    //console.log(scrapers[1]);
    //var scraperdetail = scrapers[1];
    //console.log(scraperdetail);
    //console.log(this.props.data);    
    var baseurl = this.props.scrapers[0].base_url;
  
    return (    
      <div>
      <div id="sucessNotifier" class="alert alert-success" style={{display:"none"}}>
        <div className="text-center p-3">
          <h3>Product details updated successfully!</h3>        
          <Link className="btn btn-secondary" to={{pathname: '/scraper',state: { baseurl: baseurl }}} role="button">Done</Link>         
        </div>
      </div>

      <form onSubmit={this.onSubmit}>                                 
      <table className='table table-bordered'>
      <tr>
        <td width="20%"><b>Title:</b></td>
        <td>
          <input
            type="text"
            name="title"      
            onChange={this.onChange}                                    
            value={this.state.title}
            placeholder="Enter Title"
            className="form-control" 
          />            
        </td>        
      </tr>        
      <tr>
        <td><b>Price:</b></td>
        <td>
          <input
            type="text"
            name="price"                                          
            onChange={this.onChange}                                    
            value={this.state.price}
            placeholder="Enter Price"
            className="form-control" 
          />
        </td>        
      </tr>
      <tr>
        <td><b>Description:</b></td>
        <td>
        <textarea 
          className="form-control" 
          rows="5" 
          name="description"
          onChange={this.onChange}>                        
          {this.state.description}
        </textarea>
        </td>        
      </tr> 
      <tr>
        <td className="text-center" colSpan="2">
        <Link className="btn btn-secondary" to={{pathname: '/scraper',state: { baseurl: baseurl }}} role="button">Cancel</Link>{" "} 
        <input type="submit" value="Submit" className="btn btn-primary" />
        
        
        </td>
      </tr>  
      </table>  
      </form>    
      </div>  
    );
   
  }
}

EditProductDisplay.propTypes = {
  scrapers: PropTypes.array.isRequired
};

export default EditProductDisplay;
